import { FC, Fragment, useEffect, useState } from "react";
import { navigate, PageProps } from "gatsby";

import Meta from "src/components/Meta";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";
import patchSignupURL from "src/atoms/PrismicLink/lib/patchSignupURL";
import { Button, Center, chakra, CircularProgress } from "@chakra-ui/react";
import { PrismicLink } from "src/atoms";
import PageLayout from "src/layouts/PageLayout/PageLayout";

const Inscription: FC<PageProps> = () => {
  const visitorContext = useVisitorContext();

  /**
   * Setup a false loader.
   * SSR will first render the loader while the SPA hydrates and the redirection is processed.
   */
  const [hasLoadingIndicator, setHasLoadingIndicator] = useState(true);

  useEffect(() => {
    navigate(patchSignupURL(visitorContext));

    /**
     * If the client-side redirection fails, we render some fallback UI after 1 second instead of the false loader.
     */
    const timeout = setTimeout(() => {
      setHasLoadingIndicator(false);
    }, 1000);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout>
      <Meta indexed={false} />

      {/* Fallback component if the forced redirection were to fail */}
      <Center flexDirection="column" paddingY="space-128" minHeight="500px">
        {hasLoadingIndicator ? (
          <CircularProgress isIndeterminate />
        ) : (
          <Fragment>
            <chakra.span marginY="space-32">
              Pour ouvrir un compte Shine, suivez ce lien :
            </chakra.span>

            <Button
              as={PrismicLink}
              link={{
                link_type: "Web",
                url: patchSignupURL(visitorContext),
                uid: undefined,
                type: "",
                target: undefined,
              }}
            >
              Ouvrir un compte
            </Button>
          </Fragment>
        )}
      </Center>
    </PageLayout>
  );
};

export default Inscription;
